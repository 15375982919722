import React, { useState, useEffect, Fragment, useRef, useMemo } from 'react'
import { classNames, fetchCSVData } from '../../../../utils/misc'
import useSWRImmutable from 'swr/immutable'
import { pack, hierarchy } from 'd3'
import { Portal, Transition } from '@headlessui/react'
import useMouse from '@react-hook/mouse-position'
import './CirclePackingChart.css'

import {
  Chart as ChartJS,
  LinearScale,
  PointElement,
  Tooltip,
  Legend,
} from 'chart.js'
import { usePopper } from 'react-popper'
import DownloadCsv from '../../../global/DownloadCsv'
ChartJS.register(LinearScale, PointElement, Tooltip, Legend)

const colors = [
  'text-blue-600',
  'text-green-400',
  'text-purple-600',
  'text-red-400',
  'text-orange-300',
  'text-blue-300',
  'text-green-300',
  'text-purple-300',
  'text-red-300',
  'text-orange-200',
]

let lastNonNullMouse = {
  clientX: null,
  clientY: null,
}

const isPointInsideCircle = (point, circle) => {
  const dist = Math.sqrt(
    Math.pow(point.x - circle.x, 2) + Math.pow(point.y - circle.y, 2)
  )
  return dist <= circle.r
}

const tooltip = {
  label: 'Label',
  value: '100,000',
}

const CirclePackingChart = ({ url, columns }) => {
  const { data } = useSWRImmutable(
    url,
    fetchCSVData({ [columns[1]]: 'number' })
  )
  const root = useMemo(() => {
    if (data) {
      return pack()(hierarchy({ children: data }).sum(d => d[columns[1]]))
    } else {
      return undefined
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [typeof data, url])

  const [showTooltip, setShowTooltip] = useState(false)
  const rootRef = useRef(null)
  const popperElRef = useRef(null)
  const [popperElement, setPopperElement] = useState(null)

  const mouse = useMouse(rootRef, { fps: 45 })

  useEffect(() => {
    if (mouse.clientX !== null) {
      lastNonNullMouse = mouse
      if (root) {
        // Normalize the mouse coordinates within a 0 0 1 1 viewbox
        const mousePoint = {
          x: mouse.x / rootRef.current.clientWidth,
          y: mouse.y / rootRef.current.clientHeight,
        }
        let isMouseInsideAnyCircle = false
        for (let node of root.children) {
          if (isPointInsideCircle(mousePoint, node)) {
            tooltip.label = node.data[columns[0]]
            tooltip.value = node.data[columns[1]].toLocaleString()
            isMouseInsideAnyCircle = true
            break
          }
        }
        if (isMouseInsideAnyCircle) {
          if (!showTooltip) {
            setShowTooltip(true)
          }
        } else {
          if (showTooltip) {
            setShowTooltip(false)
          }
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mouse.clientX, mouse.clientY])

  const mouseRef = useMemo(
    () => ({
      getBoundingClientRect: () => ({
        top: lastNonNullMouse.clientY - 4,
        right: lastNonNullMouse.clientX,
        bottom: lastNonNullMouse.clientY,
        left: lastNonNullMouse.clientX + 4,
        width: 0,
        height: 0,
      }),
      clientWidth: 0,
      clientHeight: 0,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [mouse]
  )

  const { styles, attributes } = usePopper(mouseRef, popperElement, {
    placement: 'top-start',
    modifiers: [
      {
        name: 'preventOverflow',
        options: {
          altAxis: true,
          padding: 16,
        },
      },
    ],
  })

  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div ref={rootRef} onMouseLeave={() => setShowTooltip(false)}>
      {root && (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1 1"
          className="text-blue-100"
        >
          {root.children.map((node, i) => (
            <g
              key={i}
              transform={`translate(${node.x},${node.y})`}
              className="circle-parent"
            >
              <clipPath id={`${columns[0]}-${i}-text`}>
                <circle r={node.r * 0.9}></circle>
              </clipPath>
              <clipPath id={`${columns[0]}-${i}-full`}>
                <circle r={node.r}></circle>
              </clipPath>
              <circle
                clipPath={`url(#${columns[0]}-${i}-full)`}
                r={node.r}
                className={classNames(
                  'circle fill-current stroke-current',
                  colors[i]
                )}
                strokeWidth="0.02"
              ></circle>
              <text
                clipPath={`url(#${columns[0]}-${i}-text)`}
                className="font-sans fill-white"
                fontSize={node.r * 0.2}
                style={{
                  alignmentBaseline: 'central',
                  textAnchor: 'middle',
                }}
              >
                {node.data[columns[0]]}
              </text>
            </g>
          ))}
        </svg>
      )}
      <div className="mt-3 flex justify-end">
        <DownloadCsv url={url} className="text-gray-300 hover:text-gray-600" />
      </div>
      <Portal>
        <div
          ref={popperElRef}
          style={styles.popper}
          {...attributes.popper}
          className="pointer-events-none"
        >
          <Transition
            as={Fragment}
            show={showTooltip}
            enter="transition-opacity duration-75"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity duration-150"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            beforeEnter={() => setPopperElement(popperElRef.current)}
            afterLeave={() => setPopperElement(null)}
          >
            <div className="px-4 py-2 bg-gray-800 shadow-md rounded">
              <p className="text-xs text-gray-200">{tooltip.label}</p>
              <p className="text-sm text-white">{tooltip.value}</p>
            </div>
          </Transition>
        </div>
      </Portal>
    </div>
  )
}

export default CirclePackingChart
