import { ArrowNarrowRightIcon } from '@heroicons/react/outline'
import { Link } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import React from 'react'
import { getImage } from '../../../utils/misc'
import Tooltip from '../../global/Tooltip'

const NestedCountry = ({ nestedCountry, tooltips }) => {
  return (
    <>
      {nestedCountry && (
        <section id="nested-country">
          <div className="container pt-10 sm:pt-12">
            <div className="bg-blue-600 rounded px-4 sm:px-10 py-4 sm:py-2 flex flex-col sm:flex-row sm:justify-between space-y-2 sm:space-y-0">
              <div className="flex items-center space-x-3">
                <p className="leading-6 font-semibold tracking-wide uppercase text-white">
                  View snapshot for nested country data
                </p>
                <Tooltip
                  title="Nested Country"
                  description={tooltips.nestedCountry}
                  defaultClass="text-white hover:text-blue-100"
                  activeClass="text-blue-100"
                />
              </div>
              <Link
                className="pl-4 pr-4 sm:pr-6 py-2 sm:hover:pr-4 transition-all  flex items-center bg-white rounded group"
                to={nestedCountry.uri}
              >
                <div className="rounded-sm p-0.5 shadow-md">
                  <GatsbyImage
                    image={getImage(nestedCountry.country.flagImage)}
                    alt={`${nestedCountry.title} flag`}
                    className="rounded-sm"
                  />
                </div>
                <h3 className="ml-4 font-semibold">{nestedCountry.title}</h3>
                <ArrowNarrowRightIcon className="box-content w-6 h-6 text-blue-600 sm:ml-4 ml-auto sm:group-hover:ml-6 transition-all" />
              </Link>
            </div>
          </div>
        </section>
      )}
    </>
  )
}

export default NestedCountry
