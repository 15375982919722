import React, { useState } from 'react'
import Toggle from '../../global/Toggle'
import TwitterIcon from '../../../assets/images/svgs/twitter-outline.svg'
import { UsersIcon } from '@heroicons/react/outline'
import RetweetIcon from '../../../assets/images/svgs/retweet.svg'
import MediaIcon from '../../../assets/images/svgs/media.svg'
import { LinkIcon } from '@heroicons/react/outline'
import HashtagIcon from '../../../assets/images/svgs/hashtag.svg'
import Tooltip from '../../global/Tooltip'

const KeyStats = ({
  stats: {
    accountsInDataset,
    percentOfAccountsWithAtLeastOneTweet,
    total,
    days90,
  },
  tooltips,
}) => {
  const [filter90Days, setFilter90Days] = useState(true)
  const currentStats = filter90Days ? days90 : total
  const secondRow = [
    {
      Icon: RetweetIcon,
      data: currentStats.percentOfTweetsThatWereRetweets,
      label: 'Were retweets',
      link: {
        text: 'Top 10 retweeted accounts',
        id: 'top-10-most-retweeted-accounts',
      },
    },
    {
      Icon: MediaIcon,
      data: currentStats.percentOfTweetsThatHadMedia,
      label: 'Had media',
      link: {
        text: 'Browse captured media gallery',
        id: 'image-gallery',
      },
    },
    {
      Icon: LinkIcon,
      data: currentStats.percentOfTweetsThatHadLinks,
      label: 'Had links',
      link: {
        text: 'Top 10 shared links',
        id: 'top-10-most-shared-links',
      },
    },
    {
      Icon: HashtagIcon,
      data: currentStats.percentOfTweetsThatUsedHashtags,
      label: 'Used hashtags',
      link: {
        text: 'Top 10 used hashtags',
        id: 'top-10-most-used-hashtags',
      },
    },
  ]
  return (
    <section id="key-stats">
      <div className="container pt-10 pb-5 space-y-4">
        <h2 className="text-xl font-medium text-gray-700">Twitter Activity</h2>
        <div className="flex space-x-3">
          <Toggle enabled={filter90Days} setEnabled={setFilter90Days} />
          <Tooltip
            title="90 Day Filter"
            description={tooltips.filterTo90Days}
          />
        </div>
        <div className="grid sm:grid-cols-3 gap-y-5 gap-x-5">
          <div className="bg-white shadow py-3 px-6 rounded-lg">
            <div className="flex items-center space-x-2.5 text-gray-700">
              <TwitterIcon className="w-5 h-5 flex-shrink-0" />
              <p className="text-3xl font-semibold">
                {currentStats.tweets.toLocaleString()}
              </p>
            </div>
            <p className="mt-1 text-sm font-medium text-gray-500">Tweets</p>
          </div>
          <div className="bg-gray-50 shadow py-3 px-6 rounded-lg">
            <div className="flex items-center space-x-2.5 text-gray-700">
              <UsersIcon className="w-5 h-5 flex-shrink-0 text-gray-400" />
              <p className="text-3xl font-semibold">
                {accountsInDataset.toLocaleString()}
              </p>
            </div>
            <p className="mt-1 text-sm font-medium text-gray-500">
              Accounts in dataset
            </p>
          </div>
          <div className="bg-gray-50 shadow py-3 px-6 rounded-lg">
            <div className="flex items-center space-x-2.5 text-gray-700">
              <UsersIcon className="w-5 h-5 flex-shrink-0 text-gray-400" />
              <p className="text-3xl font-semibold">
                {percentOfAccountsWithAtLeastOneTweet}%
              </p>
              <span className="text-sm self-end text-gray-400">
                (
                {Math.round(
                  (percentOfAccountsWithAtLeastOneTweet * accountsInDataset) /
                    100
                ).toLocaleString()}
                )
              </span>
            </div>
            <p className="mt-1 text-sm font-medium text-gray-500">
              Active accounts in dataset
            </p>
          </div>
        </div>
        <div className="grid sm:grid-cols-2 lg:grid-cols-4 gap-y-5 gap-x-5">
          {secondRow.map((stat, i) => (
            <div className="shadow rounded-lg" key={i}>
              <div className="bg-white py-4 px-6 flex items-center space-x-5">
                <div className="bg-blue-600 p-3 rounded-md text-white">
                  <stat.Icon className="w-5 h-5 flex-shrink-0" />
                </div>
                <div>
                  <div className="flex items-end space-x-2">
                    <p className="text-3xl font-semibold">
                      {stat.data.toLocaleString()}%
                    </p>
                    <span className="text-sm text-gray-400">
                      (
                      {Math.round(
                        (stat.data * currentStats.tweets) / 100
                      ).toLocaleString()}
                      )
                    </span>
                  </div>
                  <p className="text-sm font-medium text-gray-500">
                    {stat.label}
                  </p>
                </div>
              </div>
              <button
                className="w-full text-left transition-colors bg-gray-50 hover:bg-gray-100 px-4 py-2 text-sm font-medium text-blue-600"
                onClick={() => {
                  document
                    .getElementById(stat.link.id)
                    .scrollIntoView({ behavior: 'smooth' })
                }}
              >
                {stat.link.text}
              </button>
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}

export default KeyStats
