import React from 'react'
import useSWRImmutable from 'swr/immutable'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js'
import { Line } from 'react-chartjs-2'
import { fetchCSVData, useMediaQuery } from '../../../../utils/misc'

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
)

const options = {
  responsive: true,
  plugins: {
    legend: {
      display: false,
    },
  },
  scales: {
    x: {
      title: {
        display: true,
        text: 'Hour of Day',
      },
    },
    y: {
      title: {
        display: true,
        text: 'Number of Posts',
      },
    },
  },
}

const LineChart = ({ url, country }) => {
  const lg = useMediaQuery('(min-width: 64rem)')
  const { data } = useSWRImmutable(
    url,
    fetchCSVData({
      posts: 'number',
    })
  )
  let chartData
  if (data) {
    const hour_of_day_key = Object.keys(data[0])[0]
    options.scales.x.title.text = 'Hour of Day ' + hour_of_day_key.split(' ')[1]
    chartData = {
      labels: data.map(item => item[hour_of_day_key]),
      datasets: [
        {
          data: data.map(item => item.posts),
          borderColor: '#2563EB',
          backgroundColor: '#2563EB',
        },
      ],
    }
  }
  return (
    <>
      <p className="text-gray-900 text-lg font-medium text-center">
        {country} Posting Patterns
      </p>
      <div>
        {data && (
          <Line
            options={options}
            data={chartData}
            height={lg ? 200 : undefined}
          />
        )}
      </div>
    </>
  )
}

export default LineChart
