/* eslint react/jsx-no-target-blank: 0 */

import React, { useState } from 'react'
import SecondaryButton from '../../global/SecondaryButton'
import TwitterIcon from '../../../assets/images/svgs/twitter-solid.svg'
import LinkedinIcon from '../../../assets/images/svgs/linkedin.svg'
import { Transition } from '@headlessui/react'

const triangle = {
  width: 20,
  height: 10,
}

const Reference = ({ location }) => {
  const [showCopied, setShowCopied] = useState(false)
  return (
    <section id="reference">
      <div className="bg-light-blue-50">
        <div className="container py-12 sm:py-18">
          <h2 className="text-6xl font-extrabold">
            <span className="block text-gray-900">To reference this page</span>
            <span className="block text-blue-600">use the button below</span>
          </h2>
          <div className="mt-7 sm:flex space-y-8 sm:space-y-0 sm:space-x-10">
            <div className="inline-flex items-center bg-white border border-gray-200 pl-6 py-3 max-w-full">
              <span className="overflow-clip select-all">{location.href}</span>
              <div className="relative">
                <SecondaryButton
                  className="text-blue-600"
                  showArrow={false}
                  onClick={() => {
                    navigator.clipboard.writeText(location.href).then(() => {
                      if (!showCopied) {
                        setShowCopied(true)
                        setTimeout(() => {
                          setShowCopied(false)
                        }, 2000)
                      }
                    })
                  }}
                >
                  Copy
                </SecondaryButton>
                <Transition
                  show={showCopied}
                  enter="transition-opacity duration-75"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="transition-opacity duration-150"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                  className="absolute top-[calc(100%+8px)] left-1/2 -translate-x-1/2 bg-blue-600 text-white p-2 rounded text-sm"
                >
                  <div
                    className="absolute border-transparent border-b-blue-600 left-1/2 -translate-x-1/2"
                    style={{
                      top: -triangle.height,
                      borderTopWidth: 0,
                      borderRightWidth: triangle.width / 2,
                      borderBottomWidth: triangle.height,
                      borderLeftWidth: triangle.width / 2,
                    }}
                  ></div>
                  Copied!
                </Transition>
              </div>
            </div>

            <SecondaryButton
              className="text-blue-600 relative"
              onClick={() =>
                document.body.scrollIntoView({ behavior: 'smooth' })
              }
            >
              Back to top
            </SecondaryButton>
          </div>
          <div className="mt-10 sm:flex space-y-4 sm:space-y-0 sm:space-x-8">
            <a
              href={`https://twitter.com/intent/tweet?url=${encodeURIComponent(
                location.href
              )}`}
              className="flex space-x-3 group"
              target="_blank"
              rel="noopener noreferrer"
            >
              <TwitterIcon className="w-6 h-6 transition-colors text-gray-400 group-hover:text-gray-500" />
              <span className="text-lg font-medium text-gray-700">
                Share to Twitter
              </span>
            </a>
            <a
              href={`https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(
                location.href
              )}`}
              className="flex space-x-3 group"
              target="_blank"
              rel="noopener noreferrer"
            >
              <LinkedinIcon className="w-6 h-6 transition-colors text-gray-400 group-hover:text-gray-500" />
              <span className="text-lg font-medium text-gray-700">
                Share to LinkedIn
              </span>
            </a>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Reference
