const getInfo = (data, categoryColumn, countsColumn) => {
  let categories = {}
  const dataHashTable = {}
  const minDate = {
    year: data[0].year,
    month: data[0].month,
  }
  const maxDate = {
    year: data[0].year,
    month: data[0].month,
  }
  data.forEach(row => {
    if (
      row.year < minDate.year ||
      (row.year === minDate.year && row.month < minDate.month)
    ) {
      minDate.year = row.year
      minDate.month = row.month
    }
    if (
      row.year > maxDate.year ||
      (row.year === maxDate.year && row.month > maxDate.month)
    ) {
      maxDate.year = row.year
      maxDate.month = row.month
    }
    const key = `${row.year}-${row.month}-${row[categoryColumn]}`
    dataHashTable[key] = row[countsColumn]

    if (row[categoryColumn] in categories) {
      categories[row[categoryColumn]] += row[countsColumn]
    } else {
      categories[row[categoryColumn]] = row[countsColumn]
    }
  })
  categories = Object.entries(categories)
    .sort((a, b) => (a[1] < b[1] ? 1 : -1))
    .map(a => a[0])
  return {
    dataHashTable,
    categories: categories,
    minDate,
    maxDate,
  }
}

const getDateLabels = (minDate, maxDate) => {
  let year = minDate.year
  let month = minDate.month
  // const longMonthNames = [
  //   'January',
  //   'February',
  //   'March',
  //   'April',
  //   'May',
  //   'June',
  //   'July',
  //   'August',
  //   'September',
  //   'October',
  //   'November',
  //   'December',
  // ]
  const shortMonthNames = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sept',
    'Oct',
    'Nov',
    'Dec',
  ]
  const dateLabels = []
  while (
    year < maxDate.year ||
    (year === maxDate.year && month <= maxDate.month)
  ) {
    dateLabels.push({
      year,
      month,
      label: `${shortMonthNames[month - 1]} ${year}`,
    })
    if (month === 12) {
      month = 1
      year += 1
    } else {
      month += 1
    }
  }
  return dateLabels
}

export const getFormattedData = (data, stackByColumn, countsColumn) => {
  const info = getInfo(data, stackByColumn, countsColumn)
  const dateLabels = getDateLabels(info.minDate, info.maxDate)
  const colors = ['#2563EB', '#34D399', '#7C3AED', '#FDBA74', '#F43F5E']
  return {
    labels: dateLabels.map(dateLabel => dateLabel.label),
    datasets: info.categories.map((category, i) => {
      return {
        label: category,
        data: dateLabels.map(dateLabel => {
          const key = `${dateLabel.year}-${dateLabel.month}-${category}`
          if (key in info.dataHashTable) {
            return info.dataHashTable[key]
          } else {
            return 0
          }
        }),
        backgroundColor: colors[i],
      }
    }),
  }
}

export const chartOptions = {
  plugins: {
    title: {
      display: true,
      text: 'Default chart title',
    },
  },
  responsive: true,
  scales: {
    x: {
      stacked: true,
      grid: {
        display: false,
      },
      ticks: {
        maxRotation: 0,
        autoSkipPadding: 8,
        align: 'start',
      },
    },
    y: {
      stacked: true,
      grid: {
        display: false,
      },
    },
  },
}
