import React, { useEffect, useState } from 'react'
import LineChart from './LineChart'
import Toggle from '../../global/Toggle'
import { mutate } from 'swr'
import { fetchCSVData } from '../../../utils/misc'
import Tooltip from '../../global/Tooltip'
import DownloadCsv from '../../global/DownloadCsv'

const PostingPatterns = ({ postingPatterns, title, tooltips }) => {
  const [filter90Days, setFilter90Days] = useState(true)
  const current = filter90Days ? 'days90' : 'total'
  useEffect(() => {
    setTimeout(() => {
      // Prefetch total data
      mutate(
        postingPatterns.total.localFile.localURL,
        fetchCSVData({
          posts: 'number',
        })(postingPatterns.total.localFile.localURL)
      )
    }, 2000)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const url = postingPatterns[current].localFile.localURL
  return (
    <section id="posting-patterns">
      <div className="container py-8 sm:py-12">
        <div className="lg:grid lg:grid-cols-3 gap-x-12 space-y-12 lg:space-y-0">
          <div className="flex flex-col justify-center">
            <h2 className="mt-4 text-gray-400 font-semibold tracking-wide uppercase">
              Posting Activity
            </h2>
            <h3 className="mt-2 text-4xl leading-10 font-extrabold text-gray-900">
              Recorded posting patterns, captured tweets, and media data.
            </h3>
            <p className="mt-4 text-lg leading-7">
              Understanding behaviour by looking at daily patterns, captured
              tweets, and the type of media and commentary made by accounts.
            </p>
          </div>
          <div className="col-span-2">
            <LineChart country={title} url={url} />
            <div className="mt-3 flex sm:items-center justify-between lg:pl-8 flex-col sm:flex-row space-y-3 sm:space-y-0">
              <div className="flex space-x-3">
                <Toggle enabled={filter90Days} setEnabled={setFilter90Days} />
                <Tooltip
                  title="90 Day Filter"
                  description={tooltips.filterTo90Days}
                />
              </div>
              <DownloadCsv
                url={url}
                className="text-gray-300 hover:text-gray-600"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default PostingPatterns
