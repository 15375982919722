import cloneDeep from 'lodash.clonedeep'

/**
 * Flattens a post's acf fields with its wordpress fields
 * E.g. converts
 * {
 *   title,
 *   date,
 *   post: {
 *     content
 *   }
 * }
 * into
 * {
 *   title,
 *   date,
 *   content
 * }
 */

export const combineFields = (data, acfFieldGroupNames) => {
  // Clone data so we don't delete anything from original object
  const clonedData = cloneDeep(data)
  const flattenedData = {}
  acfFieldGroupNames.forEach(acfFieldGroupName => {
    // Copy the acf fields to the new object
    Object.assign(flattenedData, clonedData[acfFieldGroupName])
    // Delete the acf fields from the old object
    delete clonedData[acfFieldGroupName]
  })
  // Copy the wordpress fields to the new object
  Object.assign(flattenedData, clonedData)
  return flattenedData
}
