import React from 'react'
import { classNames } from '../../../utils/misc'
import { ArrowNarrowUpIcon } from '@heroicons/react/outline'

const SecondaryButton = ({
  as: Component,
  className = '',
  showArrow = true,
  children,
  ...props
}) => {
  return (
    <Component
      className={classNames(
        'relative inline-flex items-center justify-center text-sm font-bold py-2 cursor-pointer rounded-full px-4 space-x-2',
        className
      )}
      {...props}
    >
      <span>{children}</span>
      {showArrow && <ArrowNarrowUpIcon className="w-4 h-4" />}
    </Component>
  )
}

SecondaryButton.defaultProps = {
  as: 'button',
}

export default SecondaryButton
