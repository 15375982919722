import React, { useState } from 'react'
import { classNames } from '../../../utils/misc'
import { getDate } from '../../../utils/misc'
import { UsersIcon } from '@heroicons/react/outline'
import Tooltip from '../../global/Tooltip'
const TakedownTimeline = ({ takedownTimeline, tooltips }) => {
  const [takedownIndex, setTakedownIndex] = useState(0)
  return (
    <section id="takedown-timeline">
      <div className="container pt-4 sm:pt-6 pb-6 sm:pb-8">
        <hr className="border-blue-50" />
      </div>
      <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
        <div className="bg-blue-600 py-8 md:rounded-lg">
          <div className="container max-w-5xl">
            <div className="flex flex-col md:flex-row space-y-6 md:space-y-0 md:space-x-5">
              <div className="md:min-w-[290px]">
                <div className="flex space-x-3">
                  <h2 className="font-semibold uppercase tracking-wide text-blue-200">
                    Takedown Timeline
                  </h2>
                  <Tooltip
                    title="Takedown Timeline"
                    description={tooltips.takedownTimeline}
                    defaultClass="text-blue-200 hover:text-blue-50"
                    activeClass="text-blue-50"
                  />
                </div>

                <nav className="mt-5">
                  <ol className="overflow-hidden">
                    {takedownTimeline.map((takedown, i) => (
                      <li
                        key={i}
                        className={classNames(
                          i !== takedownTimeline.length - 1 ? 'pb-10' : '',
                          'relative'
                        )}
                      >
                        {i !== takedownTimeline.length - 1 && (
                          <div
                            className="-ml-px absolute mt-0.5 top-4 left-4 w-0.5 h-full bg-blue-300"
                            aria-hidden="true"
                          />
                        )}
                        <button
                          className="relative flex items-start group text-left"
                          aria-current="step"
                          onClick={() => setTakedownIndex(i)}
                        >
                          <span
                            className="h-9 flex items-center"
                            aria-hidden="true"
                          >
                            <span
                              className={classNames(
                                'relative z-10 w-8 h-8 flex items-center justify-center border-2 border-cyan-400 rounded-full',
                                takedownIndex === i
                                  ? 'bg-cyan-400'
                                  : 'bg-blue-600'
                              )}
                            >
                              <span
                                className={classNames(
                                  'h-2.5 w-2.5 rounded-full',
                                  takedownIndex === i
                                    ? 'bg-white'
                                    : 'bg-transparent group-hover:bg-cyan-400'
                                )}
                              />
                            </span>
                          </span>
                          <span className="ml-4 min-w-0 flex flex-col text-blue-50">
                            <span
                              className={classNames(
                                'text-xs font-semibold tracking-wide uppercase',
                                takedownIndex === i ? 'text-cyan-400' : ''
                              )}
                            >
                              {getDate(takedown.dateOfTakedown).toLocaleString(
                                'en-GB',
                                {
                                  day: 'numeric',
                                  month: 'long',
                                  year: 'numeric',
                                }
                              )}
                            </span>
                            <span className="text-sm">
                              {takedown.accountsRemoved.toLocaleString()}{' '}
                              Accounts
                            </span>
                          </span>
                        </button>
                      </li>
                    ))}
                  </ol>
                </nav>
              </div>
              <div>
                <div className="hidden sm:block">
                  <h3 className="font-semibold uppercase tracking-wide text-blue-200">
                    Accounts
                  </h3>
                  <div className="mb-8 mt-4 inline-block bg-white shadow py-3 px-6 rounded-lg md:min-w-[290px]">
                    <div className="flex items-center space-x-2.5 text-gray-700">
                      <UsersIcon className="w-5 h-5 flex-shrink-0 text-blue-600" />
                      <p className="text-3xl font-semibold">
                        {takedownTimeline[takedownIndex].accountsRemoved}
                      </p>
                    </div>
                    <p className="mt-1 text-sm font-medium text-gray-500">
                      Accounts removed
                    </p>
                  </div>
                </div>

                <h3 className="font-semibold uppercase tracking-wide text-blue-200">
                  Twitter Description
                </h3>
                <div
                  className="mt-4 prose prose-white"
                  dangerouslySetInnerHTML={{
                    __html: takedownTimeline[takedownIndex].actionTaken,
                  }}
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container pt-6 sm:pt-8 pb-4 sm:pb-6">
        <hr className="border-blue-50" />
      </div>
    </section>
  )
}

export default TakedownTimeline
