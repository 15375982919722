import React, { useState } from 'react'
import { convertToSlug } from '../../../utils/misc'
import Toggle from '../../global/Toggle'
import Tooltip from '../../global/Tooltip'
import CirclePackingChart from './CirclePackingChart'
import Table from './Table'

const TopTens = ({
  top10MostRetweetedAccounts,
  top10MostUsedHashtags,
  top10MostMentionedCountries,
  top10MostSharedLinks,
  top10MostSharedDomains,
  top10MostUsedTwitterClients,
  tooltips,
}) => {
  const [filter90Days, setFilter90Days] = useState(true)
  const current = filter90Days ? 'days90' : 'total'
  const top10CirclePackingCharts = [
    {
      title: 'Top 10 Most Retweeted Accounts',
      url: top10MostRetweetedAccounts[current].localFile.localURL,
      columns: ['account_name', 'retweets'],
      tooltip: tooltips.top10MostSharedLinks,
    },
    {
      title: 'Top 10 Most Used Hashtags',
      url: top10MostUsedHashtags[current].localFile.localURL,
      columns: ['hashtag', 'times_used'],
      tooltip: tooltips.top10MostUsedHashtags,
    },
    {
      title: 'Top 10 Most Mentioned Countries',
      url: top10MostMentionedCountries[current].localFile.localURL,
      columns: ['country', 'mentions'],
      tooltip: tooltips.top10MostMentionedCountries,
    },
  ]

  const top10Tables = [
    {
      title: 'Top 10 Most Shared Links',
      url: top10MostSharedLinks[current].localFile.localURL,
      columns: [
        {
          label: 'Link',
          name: 'link',
        },
        {
          label: 'Shares',
          name: 'shares',
        },
      ],
      tooltip: tooltips.top10MostSharedLinks,
    },
    {
      title: 'Top 10 Most Shared Domains',
      url: top10MostSharedDomains[current].localFile.localURL,
      columns: [
        {
          label: 'Domain',
          name: 'domain',
        },
        {
          label: 'Shares',
          name: 'shares',
        },
      ],
      tooltip: tooltips.top10MostSharedDomains,
    },
    {
      title: 'Top 10 Most Used Twitter Clients',
      url: top10MostUsedTwitterClients[current].localFile.localURL,
      columns: [
        {
          label: 'Twitter Client',
          name: 'twitter_client',
        },
        {
          label: 'Times Used',
          name: 'times_used',
        },
      ],
      tooltip: tooltips.top10MostUsedTwitterClients,
    },
  ]
  return (
    <section id="top-tens">
      <div className="container">
        <div className="py-8">
          <h2 className="text-xl font-medium text-gray-700">
            Twitter Activity
          </h2>
          <div className="mt-4 flex space-x-3">
            <Toggle enabled={filter90Days} setEnabled={setFilter90Days} />
            <Tooltip
              title="90 Day Filter"
              description={tooltips.filterTo90Days}
            />
          </div>
          <div className="mt-12 grid md:grid-cols-3 gap-8">
            {top10CirclePackingCharts.map((circlePackingChart, i) => (
              <div key={i} id={convertToSlug(circlePackingChart.title)}>
                <div className="flex justify-center space-x-3">
                  <h3 className="font-semibold text-gray-400 uppercase tracking-wide text-center">
                    {circlePackingChart.title}
                  </h3>
                  <Tooltip
                    title={circlePackingChart.title
                      .split(' ')
                      .slice(3)
                      .join(' ')}
                    description={circlePackingChart.tooltip}
                  />
                </div>
                <CirclePackingChart {...circlePackingChart} />
              </div>
            ))}
          </div>
        </div>
        <hr />
      </div>
      <div className="mt-6 bg-blue-600">
        <div className="container py-8 sm:pt-18 sm:pb-12">
          <div className="grid md:grid-cols-3 gap-8 sm:gap-12">
            {top10Tables.map((table, i) => (
              <div key={i} id={convertToSlug(table.title)}>
                <Table {...table} />
              </div>
            ))}
          </div>
          <hr className="mt-8 sm:mt-14" />
        </div>
      </div>
    </section>
  )
}

export default TopTens
