import React, { useState, useRef, useEffect } from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import { getImage } from '../../../utils/misc'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation } from 'swiper'
import 'swiper/css'
import './ImageGallery.css'
import {
  ArrowsExpandIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  XIcon,
} from '@heroicons/react/outline'

const ImageGallery = ({ imageGallery }) => {
  const [showImageGallery, setShowImageGallery] = useState(false)
  const [currentSlide, setCurrentSlide] = useState(0)
  const [swiper, setSwiper] = useState(null) // eslint-disable-line
  const nextRef = useRef(null)
  const prevRef = useRef(null)
  useEffect(() => {
    document.body.style.overflow = showImageGallery ? 'hidden' : 'unset'
  }, [showImageGallery])
  return (
    <>
      {imageGallery && (
        <section id="image-gallery">
          <div className="container py-8 sm:py-12">
            <h2 className="text-4xl font-extrabold text-gray-900">
              Captured media gallery
            </h2>
            <div className="mt-8 grid sm:grid-cols-2 md:grid-cols-3 gap-8">
              {imageGallery.map((image, i) => (
                <div
                  key={i}
                  className="aspect-w-1 aspect-h-1 group rounded-lg overflow-hidden"
                >
                  <div>
                    <GatsbyImage
                      image={getImage(image)}
                      alt={image.caption ?? ''}
                      className="w-full h-full"
                    />
                  </div>
                  <button
                    className="bg-black bg-opacity-50 opacity-0 group-hover:opacity-100 transition-opacity flex justify-center items-center"
                    onClick={() => {
                      setCurrentSlide(i)
                      setShowImageGallery(true)
                    }}
                  >
                    <ArrowsExpandIcon className="w-12 h-12 text-white" />
                  </button>
                </div>
              ))}
            </div>
          </div>
          {showImageGallery && (
            <div className="fixed z-50 top-0 left-0 w-screen h-screen bg-black bg-opacity-80 flex items-end md:items-center">
              <Swiper
                loop={true}
                modules={[Navigation]}
                navigation={{
                  prevEl: prevRef.current,
                  nextEl: nextRef.current,
                }}
                onBeforeInit={swiper => {
                  swiper.params.navigation.prevEl = prevRef.current
                  swiper.params.navigation.nextEl = nextRef.current
                }}
                // For some reason this is needed for the swiper arrows to work
                onSwiper={setSwiper}
                initialSlide={currentSlide}
                spaceBetween={50}
                slidesPerView={1}
              >
                {imageGallery.map((image, i) => (
                  <SwiperSlide
                    key={i}
                    className="flex flex-col items-center max-h-[calc(100vh-50px)] md:max-h-[calc(100vh-100px)] cursor-pointer"
                  >
                    <GatsbyImage
                      image={getImage(image)}
                      alt={image.caption ?? ''}
                      imgStyle={{ objectFit: 'contain' }}
                    />
                    {image.caption && (
                      <p className="mt-8 px-4 pb-4 max-w-lg mx-auto text-white text-left">
                        {image.caption}
                      </p>
                    )}
                  </SwiperSlide>
                ))}
              </Swiper>
              <button
                className="hidden md:block absolute z-10 left-0 top-1/2 transform -translate-y-1/2 p-6 group"
                ref={prevRef}
              >
                <ChevronLeftIcon className="w-12 h-12 text-white opacity-60 group-hover:opacity-100" />
              </button>
              <button
                className="hidden md:block absolute z-10 right-0 top-1/2 transform -translate-y-1/2 p-6 group"
                ref={nextRef}
              >
                <ChevronRightIcon className="w-12 h-12 text-white opacity-60 group-hover:opacity-100" />
              </button>
              <button
                className="absolute z-10 right-0 top-0 p-2 md:p-6 group"
                onClick={() => setShowImageGallery(false)}
              >
                <XIcon className="w-10 md:w-12 h-10 md:h-12 text-white opacity-60 group-hover:opacity-100" />
              </button>
            </div>
          )}
        </section>
      )}
    </>
  )
}

export default ImageGallery
