import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import { getImage, getDate } from '../../../utils/misc'
import Tooltip from '../../global/Tooltip'

const KeyInformation = ({
  title,
  flagImage,
  countryImage,
  dateReleased,
  numberOfTwitterTakedowns,
  twitterAttribution,
  twitterDescription,
  keyMessagesTargets,
  notableTactics,
  tooltips,
}) => {
  return (
    <section id="key-information">
      <div className="container">
        <div className="grid md:grid-cols-2 gap-x-18 gap-y-8">
          <div className="flex flex-col">
            <div className="flex items-center space-x-4">
              <div className="rounded-sm p-0.5 shadow-md">
                <GatsbyImage
                  image={getImage(flagImage)}
                  alt={`${title} flag`}
                  className="rounded-sm"
                />
              </div>
              <h1 className="text-4xl leading-10 font-extrabold text-gray-700">
                {title}
              </h1>
            </div>
            <div className="mt-8 flex-1 flex items-center">
              <GatsbyImage
                image={getImage(countryImage)}
                className="max-h-[500px]"
                objectFit="contain"
                alt={`${title} country`}
              />
            </div>
          </div>
          <div className="space-y-3">
            <div className="space-y-3 sm:space-y-0 sm:flex sm:space-x-16">
              <div>
                <p className="text-gray-400 font-semibold uppercase">
                  Date Released
                </p>
                <p className="text-blue-600 font-semibold">
                  {getDate(dateReleased).toLocaleString('en-GB', {
                    day: 'numeric',
                    month: 'long',
                    year: 'numeric',
                  })}
                </p>
              </div>
              <div>
                <div className="flex space-x-3">
                  <p className="text-gray-400 font-semibold uppercase">
                    Twitter Takedowns
                  </p>
                  <Tooltip
                    title="Twitter Takedowns"
                    description={tooltips.twitterTakedowns}
                  />
                </div>
                <p className="text-blue-600 font-semibold">
                  {numberOfTwitterTakedowns}
                </p>
              </div>
            </div>
            <div>
              <div className="flex space-x-3">
                <p className="text-gray-400 font-semibold uppercase">
                  <span>Twitter Attribution: </span>
                  <span className="text-gray-700">{twitterAttribution}</span>
                </p>
                <Tooltip
                  title="Twitter Attribution"
                  description={tooltips.twitterAttribution}
                />
              </div>

              <ul>
                <li className="li">{twitterDescription}</li>
              </ul>
            </div>
            <div>
              <p className="text-gray-400 font-semibold uppercase">
                Key Messages &amp; Targets
              </p>
              <ul>
                {keyMessagesTargets.map((row, i) => (
                  <li className="li" key={i}>
                    {row.item}
                  </li>
                ))}
              </ul>
            </div>
            <div>
              <p className="text-gray-400 font-semibold uppercase">
                Notable Tactics
              </p>
              <ul>
                {notableTactics.map((row, i) => (
                  <li className="li" key={i}>
                    {row.item}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default KeyInformation
