import React from 'react'
import { Switch } from '@headlessui/react'
import { classNames } from '../../../utils/misc'

const Toggle = ({
  enabled,
  setEnabled,
  label = 'Filter activity to 90 days',
  className,
  as = 'div',
}) => {
  return (
    <button
      className={classNames('flex space-x-3 cursor-pointer', className)}
      onClick={() => setEnabled(!enabled)}
    >
      <Switch
        as={as}
        checked={enabled}
        onChange={setEnabled}
        className={classNames(
          enabled ? 'bg-blue-600' : 'bg-gray-200',
          'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500'
        )}
      >
        <span className="sr-only">Use setting</span>
        <span
          aria-hidden="true"
          className={classNames(
            enabled ? 'translate-x-5' : 'translate-x-0',
            'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
          )}
        />
      </Switch>
      <p className="text-gray-700 select-none">{label}</p>
    </button>
  )
}

export default Toggle
