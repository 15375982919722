import React from 'react'
import Tooltip from '../../global/Tooltip'
import BarChart from './BarChart'

const BarCharts = ({ tweetsOverTime, accountCreationOverTime, tooltips }) => {
  const barCharts = [
    {
      title: 'Tweets Over Time',
      tooltip: tooltips.tweetsOverTime,
      chart: {
        url: tweetsOverTime.localFile.localURL,
        title: 'Tweet Language',
        categoryColumn: 'tweet_language',
        countsColumn: 'tweets',
      },
    },
    {
      title: 'Account Creation Over Time',
      tooltip: tooltips.accountCreationOverTime,
      chart: {
        url: accountCreationOverTime.localFile.localURL,
        title: 'Account Language',
        categoryColumn: 'account_language',
        countsColumn: 'accounts_created',
      },
    },
  ]

  return (
    <section id="bar-charts">
      <div className="container">
        <p className="text-5xl sm:text-7xl font-extrabold disable-custom-text-sizes">
          <span className="block text-gray-700">Here is the data</span>
          <span className="block text-blue-600">broken down.</span>
        </p>
        <hr className="mt-8" />
        <div className="mt-8 space-y-8 lg:space-y-0 lg:grid lg:grid-cols-2 lg:gap-x-8">
          {barCharts.map(barChart => (
            <div key={barChart.title}>
              <div className="flex justify-center space-x-3">
                <h2 className="text-xl leading-7 font-medium">
                  {barChart.title}
                </h2>
                <Tooltip
                  title={barChart.title}
                  description={barChart.tooltip}
                />
              </div>
              <BarChart className="-mt-5" {...barChart.chart} />
            </div>
          ))}
        </div>
        <hr className="mt-8" />
      </div>
    </section>
  )
}

export default BarCharts
