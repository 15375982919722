import React from 'react'

const About = ({ about }) => {
  return (
    <section id="about">
      <div className="container max-w-3xl">
        <article
          className="prose max-w-full"
          dangerouslySetInnerHTML={{ __html: about }}
        />
        <div className="pt-8 pb-16">
          <hr className="border-blue-50" />
        </div>
      </div>
    </section>
  )
}

export default About
