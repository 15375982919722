import React from 'react'
import { DownloadIcon } from '@heroicons/react/solid'
import { classNames } from '../../../utils/misc'

const DownloadCsv = ({ url, className }) => {
  return (
    <a
      href={url}
      target="_blank"
      rel="noreferrer"
      className={classNames(
        'flex items-center space-x-2 transition-colors',
        className
      )}
    >
      <span className="text-xs font-semibold">Download CSV</span>
      <DownloadIcon className="w-5 h-5" />
    </a>
  )
}

export default DownloadCsv
