import React from 'react'
import useSWRImmutable from 'swr/immutable'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js'
import { Bar } from 'react-chartjs-2'
import { fetchCSVData } from '../../../../utils/misc'
import { getFormattedData, chartOptions } from './utils'
import clone from 'lodash.clonedeep'
import DownloadCsv from '../../../global/DownloadCsv'

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)

const BarCharts = ({
  url,
  chartTitle,
  categoryColumn,
  countsColumn,
  ...props
}) => {
  const { data } = useSWRImmutable(
    url,
    fetchCSVData({ year: 'number', month: 'number', [countsColumn]: 'number' })
  )
  let options, chartData
  if (data) {
    options = clone(chartOptions)
    options.plugins.title.text = chartTitle
    chartData = getFormattedData(data, categoryColumn, countsColumn)
  }
  return (
    <div {...props}>
      {data && <Bar options={options} data={chartData} height="300" />}
      <div className="mt-2 flex justify-end">
        <DownloadCsv url={url} className="text-gray-300 hover:text-gray-600" />
      </div>
    </div>
  )
}

export default BarCharts
