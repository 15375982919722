import React from 'react'
import { graphql } from 'gatsby'
import { combineFields } from '../utils/combine-fields'
import Layout from '../components/global/Layout'
import GoBack from '../components/country/GoBack'
import KeyInformation from '../components/country/KeyInformation'
import NestedCountry from '../components/country/NestedCountry'
import KeyStats from '../components/country/KeyStats'
import TakedownTimeline from '../components/country/TakedownTimeline'
import About from '../components/country/About'
import Reference from '../components/country/Reference'
import BarCharts from '../components/country/BarCharts'
import TopTens from '../components/country/TopTens'
import PostingPatterns from '../components/country/PostingPatterns'
import ImageGallery from '../components/country/ImageGallery'
import Seo from '../components/global/Seo'
import { stripTags } from '../utils/misc'

const CountryTemplate = pageProps => {
  const data = {
    ...combineFields(pageProps.data.wpCountry, ['country']),
    ...pageProps.data.wp.acfOptionsSiteConfiguration.siteConfiguration,
  }
  return (
    <Layout>
      <Seo
        title={data.title}
        description={stripTags(data.about).slice(0, 250).split('.')[0]}
        image={
          data.countryImage.localFile.childImageSharp.gatsbyImageData.images
            .fallback.src
        }
      />
      <GoBack />
      <KeyInformation {...data} />
      <NestedCountry {...data} />
      <KeyStats {...data} />
      <TakedownTimeline {...data} />
      <About {...data} />
      <BarCharts {...data} />
      <TopTens {...data} />
      <PostingPatterns {...data} />
      <ImageGallery {...data} />
      <Reference {...data} location={pageProps.location} />
    </Layout>
  )
}

export default CountryTemplate

export const query = graphql`
  query CountryQuery($id: String!) {
    wpCountry(id: { eq: $id }) {
      title
      country {
        flagImage {
          localFile {
            childImageSharp {
              gatsbyImageData(layout: FIXED, width: 50)
            }
          }
        }
        countryImage {
          localFile {
            childImageSharp {
              gatsbyImageData(width: 572, quality: 100)
            }
          }
        }
        dateReleased
        numberOfTwitterTakedowns
        twitterAttribution
        twitterDescription
        keyMessagesTargets {
          item
        }
        notableTactics {
          item
        }
        nestedCountry {
          __typename
          ... on WpCountry {
            title
            uri
            country {
              flagImage {
                localFile {
                  childImageSharp {
                    gatsbyImageData(layout: FIXED, width: 50)
                  }
                }
              }
            }
          }
        }
        stats {
          accountsInDataset
          percentOfAccountsWithAtLeastOneTweet
          total {
            tweets
            percentOfTweetsThatWereRetweets
            percentOfTweetsThatHadMedia
            percentOfTweetsThatHadLinks
            percentOfTweetsThatUsedHashtags
          }
          days90 {
            tweets
            percentOfTweetsThatWereRetweets
            percentOfTweetsThatHadMedia
            percentOfTweetsThatHadLinks
            percentOfTweetsThatUsedHashtags
          }
        }
        takedownTimeline {
          dateOfTakedown
          accountsRemoved
          actionTaken
        }
        about
        tweetsOverTime {
          localFile {
            localURL
          }
        }
        accountCreationOverTime {
          localFile {
            localURL
          }
        }
        top10MostRetweetedAccounts {
          total {
            localFile {
              localURL
            }
          }
          days90 {
            localFile {
              localURL
            }
          }
        }
        top10MostUsedHashtags {
          total {
            localFile {
              localURL
            }
          }
          days90 {
            localFile {
              localURL
            }
          }
        }
        top10MostMentionedCountries {
          total {
            localFile {
              localURL
            }
          }
          days90 {
            localFile {
              localURL
            }
          }
        }
        top10MostSharedLinks {
          total {
            localFile {
              localURL
            }
          }
          days90 {
            localFile {
              localURL
            }
          }
        }
        top10MostSharedDomains {
          total {
            localFile {
              localURL
            }
          }
          days90 {
            localFile {
              localURL
            }
          }
        }
        top10MostUsedTwitterClients {
          total {
            localFile {
              localURL
            }
          }
          days90 {
            localFile {
              localURL
            }
          }
        }
        postingPatterns {
          total {
            localFile {
              localURL
            }
          }
          days90 {
            localFile {
              localURL
            }
          }
        }
        imageGallery {
          caption
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
    wp {
      acfOptionsSiteConfiguration {
        siteConfiguration {
          tooltips {
            twitterTakedowns
            twitterAttribution
            nestedCountry
            filterTo90Days
            takedownTimeline
            tweetsOverTime
            accountCreationOverTime
            top10MostRetweetedAccounts
            top10MostUsedHashtags
            top10MostMentionedCountries
            top10MostSharedLinks
            top10MostSharedDomains
            top10MostUsedTwitterClients
          }
        }
      }
    }
  }
`
