import React from 'react'
import { classNames, fetchCSVData } from '../../../../utils/misc'
import useSWRImmutable from 'swr/immutable'
import DownloadCsv from '../../../global/DownloadCsv'
import Tooltip from '../../../global/Tooltip'

const Table = ({ title, url, columns, tooltip }) => {
  const { data } = useSWRImmutable(url, fetchCSVData({}))
  const tableData = data
    ? data
    : Array(10).fill({
        [columns[0].name]: 'Loading...',
        [columns[1].name]: 'data',
      })
  return (
    <>
      <div className="flex space-x-3">
        <h3 className="font-semibold tracking-wide uppercase text-blue-100">
          {title}
        </h3>
        <Tooltip
          title={title.split(' ').slice(3).join(' ')}
          description={tooltip}
          defaultClass="text-blue-200 hover:text-blue-50"
          activeClass="text-blue-50"
        />
      </div>

      <div className="mt-2.5 shadow overflow-hidden border-b border-gray-200 rounded-md sm:rounded-lg">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              {columns.map(column => (
                <th
                  key={column.name}
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider sm:whitespace-nowrap"
                >
                  {column.label}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {tableData.map((row, i) => (
              <tr
                key={i}
                className={classNames(
                  'relative max-w-full',
                  i % 2 === 0 ? 'bg-white' : 'bg-gray-50'
                )}
              >
                <td
                  className="px-6 py-4 break-all text-sm font-medium text-gray-900 group overflow-hidden overflow-ellipsis whitespace-nowrap w-full max-w-0 select-all"
                  title={row[columns[0].name]}
                >
                  <span>{row[columns[0].name]}</span>
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                  {row[columns[1].name]}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="mt-3 flex justify-end">
        <DownloadCsv url={url} className="text-blue-300 hover:text-blue-50" />
      </div>
    </>
  )
}

export default Table
