import React from 'react'
import { Link } from 'gatsby'
import { ArrowNarrowLeftIcon } from '@heroicons/react/outline'

const GoBack = () => {
  return (
    <section id="go-back">
      <div className="container py-6 sm:py-10">
        <Link
          to="/"
          state={{ skipToCountries: true }}
          className="inline-flex items-center space-x-2 text-blue-600 hover:underline"
        >
          <ArrowNarrowLeftIcon className="w-5 h-5" />
          <span className="text-sm font-bold">Back to countries page</span>
        </Link>
      </div>
    </section>
  )
}

export default GoBack
